import React, { useLayoutEffect, useState, useEffect, useRef } from "react";
import styles from "./Doctor.module.scss";
import Footer from "../../Components/Footer";
import doctorsInfo from "../../mocks/doctorsInfo.json";
import specialistDoctorsInfo from "../../mocks/specialistDoctorsInfo.json";
import DoctorCard from "../../Components/DoctorCard";
import SpecialistDoctorCard from "../../Components/SpecialistDoctorCard";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import Modals from "../../Components/Modals";
import { BsFillStarFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import cn from "classnames";

const Doctor = () => {
  const targetRef = useRef();
  const [footerHeight, setFooterHeight] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useLayoutEffect(() => {
    var footerHeight = document.getElementById("footerHeight");
    setFooterHeight(footerHeight.offsetHeight);
  });

  useEffect(() => {
    var footerHeight = document.getElementById("footerHeight");
    const handleResize = () => {
      setFooterHeight(footerHeight.offsetHeight);
    };
    if (footerHeight) {
      window.addEventListener("resize", handleResize);
    }
  });

  const week = [
    {
      dayName: "Mon",
      dayDate: 21,
      available: false,
    },
    {
      dayName: "Tue",
      dayDate: 22,
      available: false,
    },
    {
      dayName: "Wed",
      dayDate: 23,
      available: true,
    },
    {
      dayName: "Thu",
      dayDate: 24,
      available: false,
    },
    {
      dayName: "Fri",
      dayDate: 25,
      available: false,
    },
    {
      dayName: "Sat",
      dayDate: 26,
      available: false,
    },
  ];

  const timeDetails = [
    {
      time: "09:00 AM",
      available: false,
      booked: false,
    },
    {
      time: "10:00 AM",
      available: true,
      booked: false,
    },
    {
      time: "11:00 AM",
      available: false,
      booked: false,
    },
    {
      time: "01:00 AM",
      available: false,
      booked: false,
    },
    {
      time: "02:00 AM",
      available: false,
      booked: true,
    },
    {
      time: "03:00 AM",
      available: true,
      booked: false,
    },
    {
      time: "04:00 AM",
      available: true,
      booked: false,
    },
    {
      time: "07:00 AM",
      available: true,
      booked: false,
    },
    {
      time: "08:00 AM",
      available: false,
      booked: false,
    },
  ];

  return (
    <>
      <div className={styles.doctor}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <img src="/images/logo/logo.svg" alt="logo" />
          </div>
          <div className={styles.searchWrap}>
            <div className={styles.inputWrap}>
              <CiSearch />
              <input type="text" placeholder="Search something" />
            </div>
            <div className={styles.hamburger}>
              <img src="/images/icons/hamburger.png" alt="icon" />
            </div>
          </div>
          <div className={styles.recentDoctor}>
            <h2>Your Recent Doctors</h2>
            <div className={styles.doctorCards}>
              {doctorsInfo.map((x, index) => (
                <div className={styles.caredWrap} key={index}>
                  <DoctorCard x={x} />
                </div>
              ))}
            </div>
            <div className={styles.viewAllWrap}>
              <Link to={"/my-card"} className={styles.viewAll}>
                View All
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.right} style={{ paddingBottom: footerHeight }}>
          <div className={styles.specialistDoctor}>
            <h2>Specialist Doctors</h2>
            <div className={styles.searchWrap}>
              <div className={styles.inputWrap}>
                <CiSearch />
                <input type="text" placeholder="Search something" />
              </div>
              <div className={styles.hamburger}>
                <img src="/images/icons/hamburger.png" alt="icon" />
              </div>
            </div>
            <div className={styles.specialistDoctorCards}>
              {specialistDoctorsInfo.map((x, index) => (
                <div className={styles.caredWrap} key={index}>
                  <SpecialistDoctorCard x={x} setShowModal={setShowModal} />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.footer} ref={targetRef} id="footerHeight">
            <Footer infoCard />
          </div>
        </div>
      </div>
      {/* modals */}
      <Modals
        visible={showModal}
        onClose={() => setShowModal(false)}
        content={
          <div className={styles.doctorDetailsCard}>
            <div className={styles.top}>
              <div className={styles.left}>
                <img src="/images/doctors/1.png" alt="pic" />
              </div>
              <div className={styles.right}>
                <div className={styles.name}>Dr. Marcus Horizon</div>
                <div className={styles.specialist}>Chardiologist</div>
                <div className={styles.ratingWrap}>
                  <span className={styles.icon}>
                    <BsFillStarFill />
                  </span>
                  <span className={styles.rating}>4.7</span>
                </div>
                <div className={styles.chamber}>
                  <div className={styles.icon}>
                    <MdLocationOn />
                  </div>
                  <span>800m away</span>
                </div>
              </div>
            </div>
            <h4>About</h4>
            <div className={styles.para}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam... <button>Read more</button>
            </div>
            <div className={styles.dateWrap}>
              {week.map((x, index) => (
                <div
                  className={cn(styles.singleDate, {
                    [styles.available]: x.available,
                  })}
                  key={index}
                >
                  <div className={styles.day}>{x.dayName}</div>
                  <div className={styles.date}>{x.dayDate}</div>
                </div>
              ))}
            </div>
            <div className={styles.timeWrap}>
              {timeDetails.map((x, index) => (
                <div
                  className={cn(
                    styles.time,
                    { [styles.available]: x.available },
                    { [styles.booked]: x.booked }
                  )}
                  key={index}
                >
                  {x.time}
                </div>
              ))}
            </div>
          </div>
        }
      />
    </>
  );
};

export default Doctor;
