import React from "react";
import styles from "./DoctorCard.module.scss";
import { BsFillStarFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { BiLock } from "react-icons/bi";

const DoctorCard = ({ x }) => {
  return (
    <div className={styles.doctorCard}>
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.left}>
            <img src={x.img} alt="pic" />
          </div>
          <div className={styles.right}>
            <div className={styles.name}>{x.name}</div>
            <div className={styles.specialist}>{x.specialist}</div>
            <div className={styles.ratingWrap}>
              <span className={styles.icon}>
                <BsFillStarFill />
              </span>
              <span className={styles.rating}>{x.rating}</span>
              <span className={styles.review}>({x.reviewQnt} Review)</span>
            </div>
            <div className={styles.chamber}>
              <div className={styles.icon}>
                <MdLocationOn />
              </div>
              <span>{x.chamber}</span>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <button className={styles.appointment}>Appointment</button>
          <button className={styles.message}>
            <BiLock />
            <span>Message</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;
