import React, { useLayoutEffect, useState, useEffect, useRef } from "react";
import styles from "./MyCard.module.scss";
import testList from "../../mocks/testList.json";
import yourDoctors from "../../mocks/yourDoctors.json";
import TestList from "../../Components/TestList";
import Footer from "../../Components/Footer";
import YourDoctors from '../../Components/YourDoctors';

const MyCard = () => {
    const targetRef = useRef();
    const [footerHeight, setFooterHeight] = useState(0);
  
    useLayoutEffect(() => {
      var footerHeight = document.getElementById("footerHeight");
      setFooterHeight(footerHeight.offsetHeight);
    });
  
    useEffect(() => {
      var footerHeight = document.getElementById("footerHeight");
      const handleResize = () => {
        setFooterHeight(footerHeight.offsetHeight);
      };
      if (footerHeight) {
        window.addEventListener("resize", handleResize);
      }
    });
  return (
    <div className={styles.myCard} style={{ paddingBottom: footerHeight }}>
      <div className={styles.head}>
        <img src="/images/logo/logo.svg" alt="logo" />
        <h2>Tests List</h2>
      </div>
      <div className={styles.checkups}>
        {testList.map((x, index) => (
          <div key={index} className={styles.singleCheck}>
            <TestList 
              x={x}
            />
          </div>
        ))}
      </div>
      <div className={styles.head}>
        <h2>Your Doctors</h2>
      </div>
      <div className={styles.checkups}>
        {yourDoctors.map((x, index) => (
          <div key={index} className={styles.singleCheck}>
            <YourDoctors 
              x={x}
            />
          </div>
        ))}
      </div>
        <div className={styles.footer} ref={targetRef} id="footerHeight">
          <Footer />
        </div>
    </div>
  );
};

export default MyCard;
