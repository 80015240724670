import React from 'react';
import styles from './PopularPackage.module.scss';

const popularPacks = [
    {
      icon: "/images/icons/checkupLogo.png",
      text: "Free Doctor Consultation",
    },
    {
      icon: "/images/icons/shield.png",
      text: "Safe & Hygienic",
    },
    {
      icon: "/images/icons/parcel.png",
      text: "Home Sample Pick up",
    },
    {
      icon: "/images/icons/file.png",
      text: "View Reports Online",
    },
  ];

const PopularPackage = () => {
    return (
        <div className={styles.popularPacks}>
          <h2>View Popular Packages</h2>
          <div className={styles.popularPackWrap}>
            {popularPacks.map((x, index) => (
              <div className={styles.packs} key={index}>
                <div className={styles.iconWrap}>
                  <img src={x.icon} alt="icon" />
                </div>
                <div className={styles.text}>{x.text}</div>
              </div>
            ))}
          </div>
        </div>
    );
};

export default PopularPackage;