import React from 'react';
import styles from './Page.module.scss';
import Menubar from '../Menubar';

const Page = ({content}) => {
    return (
        <div className={styles.page}>
            <div className={styles.mblLogo}>
                <img src="/images/logo/logo.svg" alt="logo" />
            </div>
            <div className={styles.menu}>
                <Menubar />
            </div>
            <div className={styles.content}>
                {content}
            </div>
        </div>
    );
};

export default Page;