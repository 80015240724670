import React, { useLayoutEffect, useState, useEffect, useRef } from "react";
import styles from "./LabTest.module.scss";
import checkups from "../../mocks/checkups.json";
import Checkups from "../../Components/Checkups";
import Footer from "../../Components/Footer";

const LabTest = () => {
    const targetRef = useRef();
    const [footerHeight, setFooterHeight] = useState(0);
  
    useLayoutEffect(() => {
      var footerHeight = document.getElementById("footerHeight");
      setFooterHeight(footerHeight.offsetHeight);
    });
  
    useEffect(() => {
      var footerHeight = document.getElementById("footerHeight");
      const handleResize = () => {
        setFooterHeight(footerHeight.offsetHeight);
      };
      if (footerHeight) {
        window.addEventListener("resize", handleResize);
      }
    });
  return (
    <div className={styles.labTest} style={{ paddingBottom: footerHeight }}>
      <div className={styles.labHead}>
        <img src="/images/logo/logo.svg" alt="logo" />
        <h2>Tests List</h2>
      </div>
      <div className={styles.checkups}>
        {checkups.map((x, index) => (
          <div key={index} className={styles.singleCheck}>
            <Checkups
              x={x}
            />
          </div>
        ))}
      </div>
        <div className={styles.footer} ref={targetRef} id="footerHeight">
          <Footer />
        </div>
    </div>
  );
};

export default LabTest;
