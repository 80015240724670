import React, { useLayoutEffect, useState, useEffect, useRef } from "react";
import styles from "./Home.module.scss";
import cn from "classnames";
import Footer from "../../Components/Footer";
import PopularPackage from "../../Components/PopularPackage";
import PopularCheckup from "../../Components/PopularCheckup";

const featuredCardDetails = [
  {
    qnt: 500000,
    text: "Lab Tests Booked",
    icon: "/images/icons/chemistry.svg",
  },
  {
    qnt: 200000,
    text: "Satisfied",
    icon: "/images/icons/award.png",
  },
];

const popularLab = [
  {
    icon: "/images/icons/blood.png",
    text: "Complete Blood Count",
  },
  {
    icon: "/images/icons/kidney.png",
    text: "Liver Function Test",
  },
  {
    icon: "/images/icons/coronavirus.png",
    text: "Lipid Profile",
  },
  {
    icon: "/images/icons/sugar-blood-level.png",
    text: "COVID-RTPCR",
  },
  {
    icon: "/images/icons/thyroid.png",
    text: "Diabetes Screening",
  },
  {
    icon: "/images/icons/lipid-profile.png",
    text: "Thyroid Profile Total",
  },
];

const nearLab = [
  {
    icon: "/images/icons/abdominoplasty.png",
    text: "USG Whole Abdomen",
  },
  {
    icon: "/images/icons/ct-scan.png",
    text: "CT Scan (Head)",
  },
  {
    icon: "/images/icons/mri.png",
    text: "MRI Brain",
  },
  {
    icon: "/images/icons/heart-rate.png",
    text: "Electrocardiography",
  },
  {
    icon: "/images/icons/x-rays.png",
    text: "X-Ray Chest PA View",
  },
  {
    icon: "/images/icons/cervical-cancer.png",
    text: "MRI Cervical Spine",
  },
  
  
  
  
  
  
];

const Home = () => {
  const targetRef = useRef();
  const [activeLabIndex, setActiveLabIndex] = useState(2);
  const [activeNearLabIndex, setActiveNearLabIndex] = useState(4);
  const [footerHeight, setFooterHeight] = useState(0);

  useLayoutEffect(() => {
    var footerHeight = document.getElementById("footerHeight");
    setFooterHeight(footerHeight.offsetHeight);
  });

  useEffect(() => {
    var footerHeight = document.getElementById("footerHeight");
    const handleResize = () => {
      setFooterHeight(footerHeight.offsetHeight);
    };
    if (footerHeight) {
      window.addEventListener("resize", handleResize);
    }
  });

  return (
    <div className={styles.home}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <img src="/images/logo/logo.svg" alt="logo" />
        </div>
        <div className={styles.featuredCardWrap}>
          {featuredCardDetails.map((x, index) => (
            <div key={index} className={styles.featuredCard}>
              <div className={styles.info}>
                <h1>{x.qnt}+</h1>
                <p>{x.text}</p>
              </div>
              <div className={styles.iconWrap}>
                <img src={x.icon} alt="icon" className={styles.icon} />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.popularCheckups}>
          <PopularCheckup />
        </div>
      </div>
      <div className={styles.right} style={{ paddingBottom: footerHeight }}>
        <div className={styles.popularPacks}>
          <PopularPackage />
        </div>
        <div className={styles.popularLab}>
          <h2>Popular Lab Test & Profiles</h2>
          <div className={styles.popularLabWrap}>
            {popularLab.map((x, index) => (
              <div
                onClick={() => setActiveLabIndex(index + 1)}
                className={cn(styles.lab, {
                  [styles.active]: activeLabIndex === index + 1,
                })}
                key={index}
              >
                <img src={x.icon} alt="icon" className={styles.icon} />
                <span className={styles.text}>{x.text}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.nearLab}>
          <h2>Visit A Lab Near You</h2>
          <div className={styles.nearLabWrap}>
            {nearLab.map((x, index) => (
              <div
                onClick={() => setActiveNearLabIndex(index + 1)}
                className={cn(styles.lab, {
                  [styles.active]: activeNearLabIndex === index + 1,
                })}
                key={index}
              >
              <img src={x.icon} alt="icon" className={styles.icon} />
              <span className={styles.text}>{x.text}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.mblPopularCheckup}>
          <PopularCheckup />
        </div>
        <div className={styles.footer} ref={targetRef} id="footerHeight">
          <Footer infoCard />
        </div>
      </div>
    </div>
  );
};

export default Home;
