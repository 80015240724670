import React from 'react';
import styles from './TestList.module.scss';

const Checkups = ({x}) => {
    return (
        <div className={styles.testList}>
            <div className={styles.top}>
                <div className={styles.checkupLogo}>
                    <img src={x.checkupLogo} alt="checkup-logo" className={styles.logo} />
                    <img src={x.checkupIcon} alt="icon" className={styles.icon} />
                </div>
                <div className={styles.mid}>
                    <h1>{x.title}</h1>
                    <p>{x.includes}</p>
                    <div className={styles.charge}>
                        <span>{x.currentCharge}</span>
                        <del>{x.previousCharge}</del>
                    </div>
                </div>
                <div className={styles.buttonWrap}>
                    <button className={styles.booking}>Booking</button>
                    <button className={styles.remove}>Remove</button>
                </div>
            </div>
            <p className={styles.cardDetails}>{x.details}</p>
        </div>
    );
};

export default Checkups;