import React from "react";
import styles from "./Footer.module.scss";

const infoCardDetails = [
  {
    icon: "/images/icons/reliability.png",
    title: "Reliable",
    text: "All products displayed on Tata 1mg are procured from verified and licensed pharmacies...",
  },
  {
    icon: "/images/icons/cyber-security.png",
    title: "Secure",
    text: "Tata 1mg uses Secure Sockets Layer (SSL) 128-bit encryption and is Payment Card ...",
  },
  {
    icon: "/images/icons/negotiation.png",
    title: "Affordable",
    text: "Find affordable medicine substitutes, save up to 50% on health products,...",
  },
];

const Footer = ({infoCard}) => {
  return (
    <div className={styles.footer}>
      {!infoCard ? null : (
        <div className={styles.infoCards}>
        {infoCardDetails.map((x, index) => (
          <div className={styles.card} key={index}>
            <img src={x.icon} alt="icon" />
            <div className={styles.info}>
              <h3>{x.title}</h3>
              <p>{x.text}</p>
            </div>
          </div>
        ))}
      </div>
      )}
      <div className={styles.bottom}>
        <p>All right reserved. &copy; {new Date().getFullYear()} Dr247.ae</p>
      </div>
    </div>
  );
};

export default Footer;
