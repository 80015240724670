import React from 'react';
import styles from "./PopularCheckup.module.scss";
import checkups from "../../mocks/checkups.json";
import Checkups from "../../Components/Checkups";
import { Link } from "react-router-dom";

const PopularCheckup = () => {
    return (
        
        <div className={styles.popularCheckups}>
          <div className={styles.head}>
            <h2>Popular Checkups</h2>
            <img
              className={styles.hamburger}
              src="/images/icons/hamburger.png"
              alt=""
            />
          </div>
          <div className={styles.popularCheckupsWrap}>
            {checkups.map((x, index) => (
              <Checkups
                x={x}
              />
            ))}
          </div>
          <div className={styles.viewAllWrap}>
            <Link to={"/my-card"} className={styles.viewAll}>
              View All
            </Link>
          </div>
        </div>
    );
};

export default PopularCheckup;