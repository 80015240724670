import React, { useState } from "react";
import styles from "./Menubar.module.scss";
import { MdDashboard } from "react-icons/md";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsClipboardPulse } from "react-icons/bs";
import { CgUserList } from "react-icons/cg";
import { Link } from "react-router-dom";
import cn from "classnames";

const menu = [
  {
    url: "/",
    icon: <MdDashboard />,
    text: "Home",
  },
  {
    url: "/doctor",
    icon: <AiOutlinePlusCircle />,
    text: "Doctor",
  },
  {
    url: "/my-card",
    icon: <CgUserList />,
    text: "My Card",
  },
  {
    url: "/lab-test",
    icon: <BsClipboardPulse />,
    text: "Lab Test",
  },
];

const Menubar = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  return (
    <div className={styles.menubar}>
      {menu.map((x, index) => (
        <Link
          key={index}
          to={x.url}
          onClick={() => setActiveIndex(index + 1)}
          className={cn(styles.singleLink, { [styles.active]: index + 1 === activeIndex })}
        >
          <span className={styles.icon}>{x.icon}</span>
          <span className={styles.text}>{x.text}</span>
        </Link>
      ))}
    </div>
  );
};

export default Menubar;
