import React from 'react';
import styles from './Modals.module.scss';

const Modals = ({content, onClose, visible}) => {
    return (
        visible ? (
            <div className={styles.modals}>
            <div onClick={onClose} className={styles.overlay}></div>
            <div className={styles.content}>
                {content}
            </div>
        </div>
        ) : null
    );
};

export default Modals;