import React from 'react';
import Home from './Home';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Page from '../Components/Page';
import LabTest from './LabTest';
import MyCard from './MyCard';
import Doctor from './Doctor';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page content={<Home />} />,
  },
  {
    path: "/doctor",
    element: <Page content={<Doctor />} />,
  },
  {
    path: "/my-card",
    element: <Page content={<MyCard />} />,
  },
  {
    path: "/lab-test",
    element: <Page content={<LabTest />} />,
  },
]);

const Routing = () => {
    return (
        <div>
            <RouterProvider router={router} />
        </div>
    );
};

export default Routing;